import { Link } from 'gatsby';
import React from 'react';

import * as links from '../links.js';
import ExternalLink from './externalLink.js';

const BrowserCol = ({ browser, children }) => {
  return (
    <div className="m-3 w-48">
      <h4>{browser}</h4>
      {children}
    </div>
  );
};

export default () => {
  return (
    <div className="w-full lg:w-1/2">
      <h2 className="mb-2">How to...</h2>
      <hx-accordion>
        <hx-accordion-panel class="border-solid border-b-2 border-0 border-gray-300">
          <header className="font-medium hxBox hxMd" slot="header">
            Set up Email
          </header>
          <div className="hxBox hxMd">
            <Link to={links.EXCHANGE_GENERAL}>
              General O365 Email Setup Instructions
            </Link>
            <div className="mt-5 flex flex-wrap">
              <BrowserCol browser="Windows">
                <ul>
                  <li>
                    <Link to={links.OUTLOOK_WINDOWS}>
                      Outlook for Windows (M365)
                    </Link>
                  </li>
                  <li>
                    <Link to={links.HELP_OUTLOOK}>
                      Help with Exchange Email
                    </Link>
                  </li>
                </ul>
              </BrowserCol>
              <BrowserCol browser="Mac">
                <ul>
                  <li>
                    <Link to={links.APPLE_MAIL}>Apple Mail</Link>
                  </li>
                  <li>
                    <Link to={links.OUTLOOK_MAC}>Outlook for Mac</Link>
                  </li>
                </ul>
              </BrowserCol>
              <BrowserCol browser="Mobile">
                <ul>
                  <li>
                    <Link to={links.OUTLOOK_ANDROID}>Android</Link>
                  </li>
                  <li>
                    <Link to={links.OUTLOOK_ANDROID_TAB}>Android Tablet</Link>
                  </li>
                  <li>
                    <Link to={links.OUTLOOK_IOS}>Outlook for iOS</Link>
                  </li>
                  <li>
                    <Link to={links.OUTLOOK_IPAD}>Outlook for iPadOS</Link>
                  </li>
                  <li>
                    <Link to={links.APPLE_MAIL_IOS}>Apple Mail for iOS</Link>
                  </li>
                  <li>
                    <Link to={links.APPLE_MAIL_IPAD}>
                      Apple Mail for iPadOS
                    </Link>
                  </li>
                </ul>
              </BrowserCol>
            </div>
          </div>
        </hx-accordion-panel>
        <hx-accordion-panel class="border-solid border-b-2 border-0 border-gray-300">
          <header className="font-medium hxBox hxMd" slot="header">
            Set up OneDrive
          </header>
          <div className="hxBox hxMd">
            <Link to={links.HELP_ONEDRIVE}>General Help with OneDrive</Link>
            <div className="mt-5 flex flex-wrap">
              <BrowserCol browser="Windows">
                <ul>
                  <li>
                    <Link to={links.ONEDRIVE_WINDOWS}>
                      OneDrive for Windows
                    </Link>
                  </li>
                </ul>
              </BrowserCol>
              <BrowserCol browser="Mac">
                <ul>
                  <li>
                    <Link to={links.ONEDRIVE_MAC}>OneDrive for Mac</Link>
                  </li>
                </ul>
              </BrowserCol>
              <BrowserCol browser="Mobile">
                <ul>
                  <li>
                    <Link to={links.ONEDRIVE_ANDROID}>Android</Link>
                  </li>
                  <li>
                    <Link to={links.ONEDRIVE_ANDROID_TAB}>Android Tablet</Link>
                  </li>
                  <li>
                    <Link to={links.ONEDRIVE_IOS}>iOS</Link>
                  </li>
                  <li>
                    <Link to={links.ONEDRIVE_IPAD}>iPadOS</Link>
                  </li>
                </ul>
              </BrowserCol>
            </div>
          </div>
        </hx-accordion-panel>
        <hx-accordion-panel class="border-solid border-b-2 border-0 border-gray-300">
          <header className="font-medium hxBox hxMd" slot="header">
            Search Email Archive
          </header>
          <div className="hxBox hxMd">
            <ul className="mt-0">
              <li>
                <ExternalLink href={links.ARCHIVING_VIDEO}>
                  Video Tutorial: Simple and Advanced Search for Rackspace Email
                  Archive{' '}
                </ExternalLink>
                (4:12 mins)
              </li>
              <li>
                Don't have archiving yet? Check out Rackspace Email Archive{' '}
                <ExternalLink href={links.ARCHIVING_DATA_SHEET}>
                  features and pricing.
                </ExternalLink>
              </li>
            </ul>
          </div>
        </hx-accordion-panel>
      </hx-accordion>
    </div>
  );
};
