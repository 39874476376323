import React from 'react';

import ExternalLink from './externalLink';

const DittoLink = ({ children: text, ...rest }) => (
  <ExternalLink {...rest} href={`https://${text}`}>
    {text}
  </ExternalLink>
);

export default DittoLink;
