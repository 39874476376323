import React from 'react';

import { SARA } from '../links.js';
import Accordion from '../components/accordion';
import Callout from '../components/callout';
import DittoLink from '../components/dittoLink';
import ExternalLink from '../components/externalLink';
import Layout from '../components/layout';
import SEO from '../components/seo';
import CollaborateIcon from '../images/collaborate.svg';
import EmailIcon from '../images/email.svg';
import ToolsIcon from '../images/tools.svg';
import { trackCustomEvent } from '../utils/googleAnalytics.js';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>How to Articles</h1>
    <div className="flex flex-wrap">
      <Callout icon={EmailIcon} title="Email Online">
        <p>
          Your email is always available online and can be accessed anywhere at{' '}
          <DittoLink
            onClick={() =>
              trackCustomEvent({
                action: 'Click',
                category: 'Support',
                label: 'Support Outlook Email',
              })
            }
          >
            outlook.office.com
          </DittoLink>
          .
        </p>
      </Callout>
      <Callout icon={CollaborateIcon} title="Download Apps">
        <p>
          Download your Microsoft 365 apps including Office and Teams at{' '}
          <DittoLink
            onClick={() =>
              trackCustomEvent({
                action: 'Click',
                category: 'Support',
                label: 'Support Microsoft Office 365 Apps',
              })
            }
          >
            portal.office.com
          </DittoLink>
          .
        </p>
      </Callout>
      <Callout icon={ToolsIcon} title="SaRA (Support and Recovery)">
        <p>
          The Microsoft Support and Recovery Assistant works by running tests to
          figure out if there is a problem.{' '}
          <ExternalLink
            href={SARA}
            onClick={() =>
              trackCustomEvent({
                action: 'Click',
                category: 'Support',
                label: 'Support SaRa - Support & Recovery Assistant',
              })
            }
          >
            Click for more details.
          </ExternalLink>
        </p>
        <small>
          <em>SaRA is compatible with Windows 10, 8, and 7.</em>
        </small>
      </Callout>
    </div>
    <div className="hxRow mt-4">
      <div className="hxCol">
        <Accordion />
      </div>
    </div>
    <div className="hxRow">
      <div className="hxCol mt-4">
        <h2>Rackspace Support Network</h2>
        Visit{' '}
        <DittoLink
          onClick={() =>
            trackCustomEvent({
              action: 'Click',
              category: 'Support',
              label: 'Support Rackspace Support website',
            })
          }
        >
          docs.rackspace.com/support/how-to/office-365
        </DittoLink>{' '}
        for additional support articles.
      </div>
    </div>
  </Layout>
);

export default IndexPage;
