import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import styles from './callout.module.scss';

const Callout = ({ children, icon: Icon, size, title }) => {
  return (
    <div className={classnames('w-full', size, 'p-4', styles.container)}>
      <div>
        <Icon className="text-gray-950" />
        <h3 className="text-2xl">{title}</h3>
      </div>
      {children}
    </div>
  );
};

Callout.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  size: PropTypes.string,
  title: PropTypes.string.isRequired,
};

Callout.defaultProps = {
  size: 'md:w-1/3',
}

export default Callout;
